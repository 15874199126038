import React, { useState, createContext, useMemo } from "react";
import PropTypes from "prop-types";

// import { googleLogout } from "@react-oauth/google";
import { setAuthUser, setIsLoading } from "../State/actions";
import AxiosWrapper from "../Api/http/AxiosWrapper";
const { REACT_APP_API_BASE_URL } = process.env;
import { toast } from "react-toastify";

import getLoginRequestData from "../Api/request/login";

const authApi = new AxiosWrapper(REACT_APP_API_BASE_URL);

const AuthContext = createContext(null);

const loginUser = async (loginData, setLoggedIn) => {
  try {
    setIsLoading(true);
    const loginRequest = getLoginRequestData();

    const loginResponse = await authApi.post(loginRequest.url, loginData);
    console.log(loginResponse);
    const userData = loginResponse?.data?.data?.user;
    console.log(userData);
    setAuthUser(userData);
    setLoggedIn(true);
    setIsLoading(false);
    toast.success("Login successfull");
  } catch (error) {
    setIsLoading(false);
    console.error(error);
    setLoggedIn(false);
    toast.error("Login failed");
  }
};

const handleLogout = async (setLoggedIn) => {
  setIsLoading(true);
  await authApi.get("/logout");
  setLoggedIn(false);
  toast.success("Logout Successfull");
  setIsLoading(false);
};

const checkLogin = async (setLoggedIn) => {
  try {
    setIsLoading(true);
    const loginRequest = getLoginRequestData();
    const checkLoginResponse = await authApi.get(loginRequest.url);
    const isLoggedInStatus = checkLoginResponse?.data?.data?.isLoggedIn;
    isLoggedInStatus && setLoggedIn(isLoggedInStatus);
    setIsLoading(false);
  } catch (error) {
    console.error(error);
    setLoggedIn(false);
    setIsLoading(false);
  }
};

const AuthProvider = ({ children }) => {
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const [loggedIn, setLoggedInState] = useState(isLoggedIn === "true");
  const setLoggedIn = (isLoggedInState) => {
    const loginstatus = isLoggedInState ? "true" : "false";
    localStorage.setItem("isLoggedIn", loginstatus);
    setLoggedInState(isLoggedInState);
  };
  const handleSuccessLogin = async (response) => {
    await loginUser(response, setLoggedIn);
  };
  const handleErrorLogin = (error) => {
    console.error(error);
    setLoggedIn(false);
    toast.error("Login Failed, Try again later");
  };

  const authContextValue = useMemo(
    () => ({
      loggedIn,
      setLoggedIn,
      handleSuccessLogin,
      handleErrorLogin,
      handleLogout: () => handleLogout(setLoggedIn),
      checkLogin: () => checkLogin(setLoggedIn),
    }),
    [loggedIn]
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthContext, AuthProvider };
