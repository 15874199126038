import React, { useEffect } from "react";
import { useAuth } from "../../Hooks/useAuth";
import { useNavigate } from "react-router-dom";

import { GoogleLogin } from "@react-oauth/google";
import "./login.css";
import { useSelector } from "react-redux";

function LogInPage() {
  const { handleSuccessLogin, handleErrorLogin, loggedIn, checkLogin }
    = useAuth();
  const authSuccessRedirect = useSelector(
    (state) => state?.auth?.authSuccessRedirect
  );

  const navigate = useNavigate();

  useEffect(() => {
    checkLogin();
  }, []);

  useEffect(() => {
    if (loggedIn && authSuccessRedirect) navigate(authSuccessRedirect);
    else if (loggedIn && !authSuccessRedirect) navigate("/admin-panel");
  }, [loggedIn]);

  return (
    <div className="container">
      <h2>Login Page</h2>
      <GoogleLogin
        onSuccess={async (response) => {
          await handleSuccessLogin(response);
          navigate(authSuccessRedirect);
        }}
        onError={handleErrorLogin}
      />
    </div>
  );
}

LogInPage.propTypes = {
  // anchorEl: PropTypes.object,
  // handleClose: PropTypes.func.isRequired,
  // changeLanguage: PropTypes.func.isRequired,
};

export default LogInPage;
