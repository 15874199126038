// src/State/index.js
import { combineReducers } from "redux";
import {
  authReducer,
  userReducer,
  projectReducer,
  pipelineReducer,
  loadingReducer,
} from "./reducers";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  project: projectReducer,
  pipeline: pipelineReducer,
  loader: loadingReducer,
});

export { rootReducer };
