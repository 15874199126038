import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
// import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// // import ListItemText from "@mui/material/ListItemText";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";
import PropTypes from "prop-types";
import Header from "../../Layouts/Header/header";
// import { Outlet } from "react-router";

import { NavLink } from "react-router-dom";

const drawerWidth = 240;

const AppBarComponent = () => {
  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
        "& .MuiToolbar-root": { padding: 0 },
      }}
    >
      <Toolbar>
        {/* <Typography variant="h6" noWrap component="div">
          Permanent drawer
        </Typography> */}
        <Header />
      </Toolbar>
    </AppBar>
  );
};

const DrawerComponent = ({ menuItems }) => {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <Divider />
      <List>
        {menuItems.map(({ text, path }) => (
          <ListItem key={text} disablePadding>
            {/* <ListItemButton> */}
            {/* <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon> */}
            {/* <ListItemText primary={text} /> */}
            <NavLink
              to={path}
              style={({ isActive }) => ({
                padding: "10px",
                margin: "10px 0",
                color: isActive ? "#fff" : "#121619",
                background: isActive ? "#121619" : "#fff",
                textDecoration: "none",
                width: "100%",
                fontStyle: "bold",
              })}
            >
              {text}
            </NavLink>
            {/* </ListItemButton> */}
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

DrawerComponent.propTypes = {
  menuItems: PropTypes.array.isRequired,
};

export default function PermanentDrawerLeft({ menuItems, children }) {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBarComponent />
      <DrawerComponent menuItems={menuItems} />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          padding: 3,
          mt: "10rem",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

PermanentDrawerLeft.propTypes = {
  menuItems: PropTypes.array.isRequired,
  children: PropTypes.node,
};
