import React from "react";

const SettingsPage = () => {
  return (
    <div className="jenkins-project-manpipelineIdment-container">
      <h1> Settings Page</h1>
    </div>
  );
};

export default SettingsPage;
