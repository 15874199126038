import * as React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import PropTypes from "prop-types";

export default function CustomAlert({ severity, title, description }) {
  return (
    <Alert severity={severity} sx={{ margin: "20px" }}>
      <AlertTitle>{title}</AlertTitle>
      {description}
    </Alert>
  );
}

CustomAlert.propTypes = {
  severity: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
