import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
const { REACT_APP_API_BASE_URL } = process.env;
import AxiosWrapper from "../../Api/http/AxiosWrapper";

import CustomAlert from "../../Components/Alerts/Alert";

const apiInstance = new AxiosWrapper(REACT_APP_API_BASE_URL);

const rejectPipelineTriggerRequests = async (
  requestId,
  setAlertDesription,
  setIsError
) => {
  try {
    const response = await apiInstance.post(
      `/pipeline-trigger-requests/${requestId}/status`,
      { status: "REJECTED" }
    );
    const description
      = response?.data?.data?.message
      || "Pipeline Trigger request approved successfully ";
    setIsError(false);
    setAlertDesription(description);
    toast.success(description);
  } catch (error) {
    console.error(error?.message);
    const errorDescription
      = error?.response?.data?.message
      || "Pipeline Trigger request approval failed. Invalid Request";
    setIsError(true);
    setAlertDesription(errorDescription);

    toast.error(errorDescription);
  }
};

const PipelineTriggerRequestReject = () => {
  const { requestId } = useParams();
  const [description, setDescription] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    return rejectPipelineTriggerRequests(requestId, setDescription, setIsError);
  }, []);

  return (
    <div>
      {!isError ? (
        <CustomAlert
          severity="success"
          title="Pipeline Trigger Request Rejected Successfully"
          description={description}
        />
      ) : (
        <CustomAlert
          severity="error"
          title="Pipeline Trigger Request Rejection failed"
          description={description}
        />
      )}
    </div>
  );
};

export default PipelineTriggerRequestReject;
