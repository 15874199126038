import React from "react";
import { AuthProvider } from "../src/Contexts/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routers from "./Routes/routers";
import "./App.css";

import CustomLoader from "./Components/CustomLoader/CustomLoader";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Routers />
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          icon={true}
          closeButton={false}
        />
      </AuthProvider>
      <CustomLoader />
    </div>
  );
}

export default App;
