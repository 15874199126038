import axios from "axios";

const DEFAULT_TIMEOUT = 5000;

class AxiosWrapper {
  constructor(baseURL) {
    this.axiosInstance = axios.create({
      baseURL: baseURL,
      timeout: DEFAULT_TIMEOUT,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });

    this.setupInterceptors();
  }

  setupInterceptors() {
    // this.axiosInstance.interceptors.request.use(
    //   (config) => {
    //     const token = this.getAuthToken();
    //     if (token) {
    //       config.headers["Authorization"] = `Bearer ${token}`;
    //     }

    //     return config;
    //   },
    //   (error) => {
    //     return Promise.reject(error);
    //   },
    // );

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  // setAuthToken(token) {
  //   localStorage.setItem("authToken", token);
  // }

  // getAuthToken() {
  //   return localStorage.getItem("authToken");
  // }

  // removeAuthToken() {
  //   localStorage.removeItem("authToken");
  // }

  // authenticate(token) {
  //   this.setAuthToken(token);
  // }

  // deauthenticate() {
  //   this.removeAuthToken();
  // }

  get(url, params = {}) {
    return this.axiosInstance.get(url, { params });
  }

  post(url, data = {}) {
    return this.axiosInstance.post(url, data);
  }

  put(url, data = {}) {
    return this.axiosInstance.put(url, data);
  }

  delete(url, params = {}) {
    return this.axiosInstance.delete(url, { params });
  }
}

export default AxiosWrapper;
