import React from "react";
import PropTypes from "prop-types";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
  useLocation,
} from "react-router-dom";

// import Header from "../Layouts/Header/header";
// import Footer from "../Layouts/Footer/footer";

import AdminPanel from "../Pages/AdminPanelPage/adminPanel";
import UserManagementPage from "../Pages/AdminPanelPage/UserManagementPage/UserManagementPage";

import PipelineManagement from "../Pages/AdminPanelPage/PipelineManagementPage/pipelineManagement";
import ProjectManagementPage from "../Pages/AdminPanelPage/ProjectManagementPage/ProjectManagementPage";
import SettingsPage from "../Pages/AdminPanelPage/SettingsPage/SettingsPage";
// import JenkinsProject from "../Pages/AdminPanelPage/JenkinsProjectManagementPage/JenkinsProjectManagementPage";
import PipelineRequestApprove from "../Pages/PipelineTriggerRequestApprovalPage/PipelineTriggerRequestApprovalPage";
import PipelineRequestReject from "../Pages/PipelineTriggerRequestRejectionPage/PipelineTriggerRequestRejectionPage";

import { useAuth } from "../Hooks/useAuth";

import LogIn from "../Pages/LoginPage/login";
import { setAuthSuccessRedirect } from "../State/actions";

const PrivateRoute = ({ element }) => {
  const { loggedIn } = useAuth();
  const location = useLocation();
  console.log("private route isLoggedIn" + loggedIn);
  setAuthSuccessRedirect(location.pathname);

  return loggedIn ? element : <Navigate to="/signin" replace />;
};

PrivateRoute.propTypes = {
  element: PropTypes.node.isRequired,
};

const Routers = () => {
  return (
    <Router>
      <Routes>
        <Route path="/signin" element={<LogIn />} />
        <Route
          path="/pipeline-trigger-requests/:requestId/approve"
          element={<PrivateRoute element={<PipelineRequestApprove />} />}
        />
        <Route
          path="/pipeline-trigger-requests/:requestId/reject"
          element={<PrivateRoute element={<PipelineRequestReject />} />}
        />

        <Route
          path="/admin-panel"
          element={<PrivateRoute element={<AdminPanel />} />}
        >
          <Route
            index
            path="user-management"
            element={<PrivateRoute element={<UserManagementPage />} />}
          />
          <Route
            path="pipeline-management"
            element={<PrivateRoute element={<PipelineManagement />} />}
          />
          <Route
            path="project-management"
            element={<PrivateRoute element={<ProjectManagementPage />} />}
          />
          <Route
            path="settings"
            element={<PrivateRoute element={<SettingsPage />} />}
          />
        </Route>

        <Route path="/" element={<Navigate to="/signin" replace={true} />} />
      </Routes>
    </Router>
  );
};

export default Routers;
