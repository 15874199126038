import { USERS_API } from "../resources/endPoint";

const getUsersData = () => {
  return {
    url: USERS_API,

    method: "get",
    //   tag: ApiTag.CSRF_TOKEN,
    headers: { "content-type": "application/json" },
    authRequired: false,
    csrfToken: true,
  };
};
export default getUsersData;
