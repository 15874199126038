import React from "react";
import { useAuth } from "../../Hooks/useAuth";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Signout() {
  const { handleLogout } = useAuth();
  const { t: translator } = useTranslation();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await handleLogout();
      navigate("/signin");
    } catch (error) {
      console.error(error);
      alert("logout failed");
    }
  };

  return (
    <div>
      <Button label={translator("menu.logout")} onClick={handleSignOut} />
    </div>
  );
}

export default Signout;
