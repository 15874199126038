import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const handleSubmit = (event, setOpen, handleFormSubmit, setFormState) => {
  event.preventDefault();
  handleFormSubmit();
  handleClose(null, setOpen, setFormState);
};

const handleClose = (event, setOpen, resetFormState) => {
  setOpen(false);
  resetFormState({});
};

const handleSelectChange = (event, id, formState, setSelectState) => {
  console.log(event);
  setSelectState({ ...formState, [id]: event.target.value });
};

const handleTextChange = (event, id, formState, setTextState) => {
  console.log(event);
  setTextState({ ...formState, [id]: event.target.value });
};

const FormInput = ({ input, setFormState, formState }) => {
  if (input.type === "text") {
    return (
      <TextField
        autoFocus
        required={input.required}
        margin="dense"
        id={input.name}
        name={input.name}
        label={input.label}
        type={input.type}
        fullWidth
        variant="standard"
        key={input.name}
        sx={{ width: "80%", margin: "20px" }}
        onChange={(event) =>
          handleTextChange(event, input.id, formState, setFormState)
        }
        defaultValue={formState[input?.id]}
      />
    );
  } else if (input.type === "select") {
    return (
      <FormControl fullWidth sx={{ margin: "20px", width: "80%" }}>
        <InputLabel id={input.label}>{input.label}</InputLabel>
        <Select
          labelId={input.label}
          id={input.id}
          value={formState[input.id]}
          label={input.label}
          onChange={(event) =>
            handleSelectChange(event, input.id, formState, setFormState)
          }
        >
          {input?.options?.map((option) => {
            return (
              <MenuItem value={option.value} key={option.label}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  return null;
};

FormInput.propTypes = {
  input: PropTypes.object,
  formState: PropTypes.object,
  setFormState: PropTypes.func,
};

export default function FormDialog({
  open,
  setOpen,
  formInputs,
  contentText,
  title,
  formState,
  setFormState,
  handleFormSubmit,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={(event) => handleClose(event, setOpen, setFormState)}
        PaperProps={{
          component: "form",
          onSubmit: (event) =>
            handleSubmit(event, setOpen, handleFormSubmit, setFormState),
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {contentText && <DialogContentText>{contentText}</DialogContentText>}
          {formInputs?.map((input) => {
            return (
              <FormInput
                input={input}
                key={input.id}
                formState={formState}
                setFormState={setFormState}
              />
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => handleClose(event, setOpen, setFormState)}
          >
            Cancel
          </Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

FormDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  formInputs: PropTypes.array,
  title: PropTypes.string,
  contentText: PropTypes.string,
  formState: PropTypes.object,
  setFormState: PropTypes.func,
  handleFormSubmit: PropTypes.func,
};
