import React, { useEffect } from "react";

import "./adminPanel.css";

// import BasicTabs from "../../Components/Tabs/Tabs";
// import UserManagement from "./UserManagementPage/UserManagementPage";
// import ProjectManagement from "./ProjectManagementPage/ProjectManagementPage";
// import JenkinsProjectManagement from "./JenkinsProjectManagementPage/JenkinsProjectManagementPage";
// import PipelineManagement from "./PipelineManagementPage/pipelineManagement";
import PermanentDrawerLeft from "../../Components/PermanentDrawerLeft/PermanentDrawerLeft";

import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";

import { useAuth } from "../../Hooks/useAuth";

const tabs = [
  {
    text: "User management",
    path: "/admin-panel/user-management",
  },
  {
    text: "Project Management",
    path: "/admin-panel/project-management",
  },

  {
    text: "Pipeline Management",
    path: "/admin-panel/pipeline-management",
  },
];

const AdminPanel = () => {
  const { pathname } = useLocation();
  const { checkLogin, loggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    checkLogin();
  }, []);

  useEffect(() => {
    if (!loggedIn) navigate("/signin");
  }, [loggedIn]);

  return (
    <div className="admin-panel-container">
      {/* <h2 className="admin-panel-heading">Admin Panel Page</h2> */}

      <PermanentDrawerLeft menuItems={tabs}>
        {pathname === "/admin-panel" ? (
          <Navigate to="/admin-panel/user-management" replace />
        ) : (
          <Outlet />
        )}
      </PermanentDrawerLeft>
    </div>
  );
};

export default AdminPanel;
