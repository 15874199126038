import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Logout from "../../Components/SignOut/signOut";
import { useAuth } from "../../Hooks/useAuth";

import PropTypes from "prop-types";
import "react-toastify/dist/ReactToastify.css";

import "./header.css";

function LinksContainer({ loggedIn }) {
  return (
    <div className="linksContainer">
      <Link to="/admin-panel">Admin Panel</Link>
      <Link to="/admin-panel/settings">Settings</Link>

      {loggedIn && <Logout />}
    </div>
  );
}

LinksContainer.propTypes = {
  translator: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
};

function RightSide({ translator, loggedIn }) {
  return (
    <div className="rightSide">
      <LinksContainer translator={translator} loggedIn={loggedIn} />
    </div>
  );
}

RightSide.propTypes = {
  translator: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  handleToggleHiddenLinks: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
};

function Header() {
  const { loggedIn } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/") {
    console.log(location.pathname);

    return !loggedIn ? navigate("/signin") : navigate("/admin-panel");
  }

  return (
    <div className="header">
      <RightSide loggedIn={loggedIn} />
    </div>
  );
}

export default Header;
