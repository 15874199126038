import { PROJECTS_API } from "../resources/endPoint";

const getProjectsData = () => {
  return {
    url: PROJECTS_API,

    method: "get",
    //   tag: ApiTag.CSRF_TOKEN,
    headers: { "content-type": "application/json" },
    authRequired: false,
    csrfToken: true,
  };
};
export default getProjectsData;
